import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { countriesReducer } from './reducers/countries.reducer';
import { CountriesEffects } from './effects/countries.effects';
import { COUNTRIES_FEATURE_KEY } from './keys';

@NgModule({
    imports: [StoreModule.forFeature(COUNTRIES_FEATURE_KEY, countriesReducer), EffectsModule.forFeature([CountriesEffects])],
})
export class CountriesStoreModule {}
