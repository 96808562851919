import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CountriesFacade } from '../facades/countries.facade';

export function countriesCleanupGuard(): CanActivateFn {
    return () => {
        const countriesFacade = inject(CountriesFacade);
        countriesFacade.clear();
        return true;
    };
}
