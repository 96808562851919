export class Country {
    name: string;
    code2: string;
    code3: string;

    constructor(data: string) {
        this.name = data;
        this.code2 = data;
        this.code3 = data;
    }
}
