import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CountriesState } from '../reducers/countries.reducer';
import { COUNTRIES_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<CountriesState>(COUNTRIES_FEATURE_KEY);

const selectAvailable = createSelector(selectState, (state) => {
    return state.available;
});

const selectAvailableList = createSelector(selectState, (state) => {
    return (state.available || []).map((country) => ({
        label: country.name,
        value: country.code3,
    }));
});

const selectAvailableCode3 = createSelector(selectState, (state) => {
    return state.available?.map((country) => country.code3) || null;
});

const selectAvailableNames = createSelector(selectState, (state) => {
    return state.available?.map((country) => country.name) || null;
});

const selectAll = createSelector(selectState, (state) => {
    return state.all;
});

const selectAllList = createSelector(selectState, (state) => {
    return (state.all || []).map((country) => ({
        label: country.name,
        value: country.code3,
    }));
});

const selectAllCode3 = createSelector(selectState, (state) => {
    return state.all?.map((country) => country.code3) || null;
});

const selectAllNames = createSelector(selectState, (state) => {
    return state.all?.map((country) => country.name) || null;
});

const selectLoading = createSelector(selectState, (state) => {
    return state.loading;
});

const selectLoaded = createSelector(selectState, (state) => {
    return state.loaded;
});

export {
    selectAvailable,
    selectAvailableList,
    selectAvailableCode3,
    selectAvailableNames,
    selectAll,
    selectAllList,
    selectAllCode3,
    selectAllNames,
    selectLoading,
    selectLoaded,
};
