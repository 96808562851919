import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Country } from '../models';

export const countriesActions = createActionGroup({
    source: 'Countries',
    events: {
        'Get Countries': emptyProps(),
        'Get Countries Success': props<{ payload: [Country[], Country[]] }>(),
        'Get Countries Error': emptyProps(),
        Clear: emptyProps(),
    },
});
