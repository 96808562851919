import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { Country } from '../models/country.model';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getCountries(): Observable<[Country[], Country[]]> {
        return forkJoin([
            this.httpClient
                .get<ApiResponse<{ countries: string[] }>>(`config/available-countries`)
                .pipe(map((response) => response.data.countries.map((data) => new Country(data)))),
            this.httpClient
                .get<ApiResponse<{ countries: string[] }>>(`config/all-countries`)
                .pipe(map((response) => response.data.countries.map((data) => new Country(data)))),
        ]);
    }
}
