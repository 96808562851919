import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, take, tap } from 'rxjs';
import {
    selectLoaded,
    selectLoading,
    selectAvailable,
    selectAvailableList,
    selectAvailableCode3,
    selectAvailableNames,
    selectAll,
    selectAllList,
    selectAllCode3,
    selectAllNames,
} from '../selectors/countries.selector';
import { Country } from '../models';
import { countriesActions } from '../actions/countries.actions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class CountriesFacade {
    private readonly translateService = inject(TranslateService);

    available$ = this.store.select(selectAvailable).pipe(filter((country): country is Country[] => country !== null));
    availableList$ = this.store.select(selectAvailableList);
    availableCode3$ = this.store.select(selectAvailableCode3).pipe(filter((country): country is string[] => country !== null));
    availableNames$ = this.store.select(selectAvailableNames).pipe(filter((country): country is string[] => country !== null));
    availableListTranslated$ = this.availableList$.pipe(
        map((countries) =>
            countries.map((country) => ({
                label: <string>this.translateService.instant(`COUNTRY.${country.label}`),
                value: country.value,
            })),
        ),
    );

    all$ = this.store.select(selectAll).pipe(filter((country): country is Country[] => country !== null));
    allList$ = this.store.select(selectAllList);
    allCode3$ = this.store.select(selectAllCode3).pipe(filter((country): country is string[] => country !== null));
    allNames$ = this.store.select(selectAllNames).pipe(filter((country): country is string[] => country !== null));
    allListTranslated$ = this.allList$.pipe(
        map((countries) =>
            countries
                .map((country) => ({
                    label: <string>this.translateService.instant(`COUNTRY.${country.label}`),
                    value: country.value,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        ),
    );

    loading$ = this.store.select(selectLoading).pipe(filter((loading): loading is boolean => loading !== null));
    loaded$ = this.store.select(selectLoaded);

    constructor(private store: Store) {}

    get availableCode3() {
        let value = [] as string[];
        this.store
            .select(selectAvailableCode3)
            .pipe(
                take(1),
                filter((data): data is string[] => data !== null),
                tap((data) => (value = data)),
            )
            .subscribe();
        return value;
    }

    getCountries(): void {
        this.store.dispatch(countriesActions.getCountries());
    }

    clear(): void {
        this.store.dispatch(countriesActions.clear());
    }
}
