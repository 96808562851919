import { Injectable } from '@angular/core';
import { GuiFacade } from '@app/gui/store/facades/gui-facade';
import { HttpService } from '../services/http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, finalize } from 'rxjs';
import { countriesActions } from '../actions/countries.actions';

@Injectable()
export class CountriesEffects {
    getCountries$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(countriesActions.getCountries),
            mergeMap(() => {
                this.guiFacade.showLoader('get-countries');
                return this.httpService.getCountries().pipe(
                    map((response) => countriesActions.getCountriesSuccess({ payload: response })),
                    catchError(() => of(countriesActions.getCountriesError())),
                    finalize(() => this.guiFacade.hideLoader('get-countries')),
                );
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
        private guiFacade: GuiFacade,
    ) {}
}
