import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, take, tap } from 'rxjs';
import { CountriesFacade } from '../facades/countries.facade';

export const countriesLoadedGuard: CanActivateFn = () => {
    const countriesFacade = inject(CountriesFacade);
    return countriesFacade.loaded$.pipe(
        tap((loaded) => {
            if (loaded !== true) {
                countriesFacade.getCountries();
            }
        }),
        filter((loaded): loaded is true => loaded === true),
        take(1),
    );
};
